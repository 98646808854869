import type { Directive, ObjectDirective } from 'vue'

export const vAntdInputClosable: Directive<HTMLElement> = {
  mounted(el) {
    const $input = el.querySelector('input') as HTMLInputElement
    const $inputWrapper = $input.parentElement!
    const $closeBtn = document.createElement('div')
    const $closeIcon = document.createElement('i')
    
    $closeBtn.className = 'hidden justify-center items-center p-2 cursor-pointer absolute top-1/2 right-0 z-1 translate-y--1/2 text-#8098BF active:text-white'
    $closeIcon.className = 'i-ant-design:close-circle-outlined'
    $closeBtn.appendChild($closeIcon)
    $inputWrapper.appendChild($closeBtn)
    $inputWrapper.style.setProperty('position', 'relative')
    
    $closeBtn.addEventListener('click', () => {
      $input.value = ''
      $input.dispatchEvent(new Event('input'))
    })
    
    const toggleDisplay = () => setTimeout(() => $closeBtn.style.setProperty('display', $input.value ? 'flex' : 'none'))
    $input.addEventListener('change', toggleDisplay)
    $input.addEventListener('input', toggleDisplay)
    toggleDisplay()
    
    // 创建一个MutationObserver实例
    const observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'attributes' && mutation.attributeName === 'aria-valuenow')
          toggleDisplay()
      }
    })
    
    // 监听input元素的value属性的变化
    observer.observe($input, { attributes: true })
  },
}
